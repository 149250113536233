import { getLngAllowed } from '@nickel/i18n'
import { SupportedLocale } from '@nickel/i18n/lib/types'

import { dictionnary } from 'src/i18n'
import { AUTHORIZE_LANG_COUNTRY } from 'src/routes/constants'

export const getLangFromPath = (path: string) => {
    return path.split('/')[1].slice(0, 2)
}

export const getLangAndCountryFromPath = (path: string) => {
    const lang = getLangFromPath(path)
    const country = path.split('/')[1].slice(-2).toUpperCase()
    const key = `${lang.toUpperCase()}_${country}` as unknown as AUTHORIZE_LANG_COUNTRY
    return { lang, country, key }
}

export const findLanguage = (language: SupportedLocale) =>
    getLngAllowed(dictionnary).find((value) => value === language) as SupportedLocale

export const formattedStringToSupportedLocal = (path: string) => {
    if (!path || !path.includes('/')) return SupportedLocale.FR_FR
    const { lang, country } = getLangAndCountryFromPath(path)

    if (lang === 'en') return SupportedLocale.EN_US
    return `${lang}-${country}` as SupportedLocale
}

export const getCurrentLanguageFromUrl = (pathname: string) => findLanguage(formattedStringToSupportedLocal(pathname))
