import React, { useContext, useMemo, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import Button from '@nickel/core-ui/components/Button'
import { ArrowLeft, Search, Settings } from '@nickel/core-ui/components/Icons'
import { noop } from '@nickel/core-ui/utils/utils'

import { getFilterCount } from '../MapWrapper/components/Filters/domain/domain'
import { FilterChip } from '../MapWrapper/components/Filters/FilterChip'
import { FilterButton, FilterNotification } from '../MapWrapper/components/Filters/styles'

import { SearchBarInput } from './components/SearchBarInput/SearchBarInput'
import { MobileSeparator, SearchBarElementsWrapper, SearchBarWrapper, SearchButton, SearchTitle } from './styles'

import { AppContext } from 'src/context'
import { AppActionsType } from 'src/context/types'
import { Localisation } from 'src/features/SearchBar/components/Localisation/Localisation'
import { useMediaQuery } from 'src/hooks/useMediaQuery'
import { enableScroll } from 'src/utils/layout/layout'
import { SnowplowAction, trackClickEvent } from 'src/utils/Tracking/utils'

export function SearchBar({ isMobileFullScreen }: Readonly<{ isMobileFullScreen: boolean }>) {
    const { t } = useTranslation('home')
    const isDesktop = useMediaQuery()
    const [filterDrawerOpen, setFilterDrawerOpen] = useState(false)
    const { state, dispatch } = useContext(AppContext)
    const _getFilterCount = useMemo(() => getFilterCount(state.filters), [state.filters])

    const [searchQuery, setSearchQuery] = useState('')
    const [searching, setSearching] = useState<boolean>(false)

    const [, setSearchParams] = useSearchParams()

    const _openMobileFilter = () => {
        dispatch({ type: AppActionsType.SET_ENABLE_OVERLAY })
        setFilterDrawerOpen(true)
        trackClickEvent(SnowplowAction.FILTER)
    }

    const resetDrawer = () => {
        if (!isMobileFullScreen) {
            enableScroll()
            dispatch({ type: AppActionsType.SET_DISABLE_OVERLAY })
        }
        setFilterDrawerOpen(false)
    }

    const closeFullScreen = () => {
        dispatch({ type: AppActionsType.RESET })
        setSearchQuery('')
        setSearchParams()
    }

    return (
        <SearchBarWrapper $isMobileFullScreen={isMobileFullScreen} data-testid="searchBar#wrapper">
            <SearchTitle data-testid="searchBar#title">{t('findNickelPos')}</SearchTitle>
            <SearchBarElementsWrapper $isMobileFullScreen={isMobileFullScreen}>
                {isMobileFullScreen && (
                    <Button Icon={ArrowLeft} onClick={closeFullScreen} testId="closeFullScreen" variant="ghost" />
                )}
                <Localisation isMobileFullScreen={isMobileFullScreen} />
                {!isDesktop && <MobileSeparator data-testid="searchBar#mobile#separator">{t('or')}</MobileSeparator>}
                <SearchBarInput
                    isMobileFullScreen={isMobileFullScreen}
                    searchQuery={searchQuery}
                    setSearching={setSearching}
                    setSearchQuery={setSearchQuery}
                />
                {!isDesktop && (
                    <FilterButton $isIconButton={isMobileFullScreen} onClick={_openMobileFilter}>
                        <Button
                            Icon={isMobileFullScreen ? Settings : undefined}
                            onClick={() => undefined}
                            testId="openMobileFilter"
                            text={isMobileFullScreen ? '' : t('filterActionMobile')}
                            variant="ghost"
                        />
                        {_getFilterCount > 0 && <FilterNotification>{_getFilterCount}</FilterNotification>}
                    </FilterButton>
                )}
                {!isDesktop && <FilterChip filterDrawerOpen={filterDrawerOpen} resetDrawer={resetDrawer} />}
                <SearchButton
                    className="alignSelf"
                    Icon={Search}
                    onClick={noop}
                    pending={searching}
                    testId="search"
                    text={t('searchButton')}
                    variant="primary"
                />
            </SearchBarElementsWrapper>
        </SearchBarWrapper>
    )
}
