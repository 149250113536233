import React, { MouseEvent } from 'react'

import { Option } from '@nickel/core-ui/components/SearchInput/types'

import { ButtonPrediction, ListPrediction } from './styles'

export function ResultPrediction({
    onSelect,
    predictions
}: Readonly<{
    onSelect: (id: string) => (e: MouseEvent) => void
    predictions: Option[]
}>) {
    return (
        <ListPrediction data-testid="mobileSuggestion#list">
            {predictions.map(({ label, value }) => (
                <ButtonPrediction key={label} onClick={(e: MouseEvent) => onSelect(value)(e)} type="button">
                    {label}
                </ButtonPrediction>
            ))}
        </ListPrediction>
    )
}
