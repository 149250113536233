import React from 'react'

import { useTranslation } from 'react-i18next'

import Pill from '@nickel/core-ui/components/Pill'

import { getOpeningTag } from './domain/domain'

import { useLangCountry } from 'src/hooks/useLangCountry'
import { OpeningDay } from 'src/services/annuaire-buraliste-api-v3'

export function OpeningTag({
    openingDays,
    timezone
}: Readonly<{
    openingDays: OpeningDay[]
    timezone?: string
}>) {
    const { t } = useTranslation('home')
    const { country, lang } = useLangCountry()

    const tagStatus = getOpeningTag(openingDays, lang, country, t, timezone)

    return <Pill border={false} small text={tagStatus.text} variant={tagStatus.state} />
}
