import React from 'react'

import Spinner from '@nickel/core-ui/components/Spinner'
import { References } from '@nickel/foundations/tokens/color'

import { Wrapper } from './styles'

export function LoadingSearchResult() {
    return (
        <Wrapper data-testid="emptySearchResult">
            <Spinner color={References.REF_NEUTRAL_N300} size="large" />
        </Wrapper>
    )
}
