import React, { ReactNode, useEffect } from 'react'

import ReactDOM from 'react-dom/client'
import { StyleSheetManager } from 'styled-components'

import Toaster from '@nickel/core-ui/components/Toaster/Toaster'
import { ToasterProps } from '@nickel/core-ui/components/Toaster/types'

import { ToasterSlot, Wrapper } from './styles'

enum ToastEvent {
    PUSH = 'pushToast',
    CLEAR_ALL = 'clearAllToasts'
}

const getToasterContainer = (shadowRoot?: ShadowRoot | null) =>
    (shadowRoot ?? document).querySelector('#toasterContainer')

const getShadowDomContainer = (toasterContainer: Element) => {
    const styleSlot = document.createElement('div')
    const renderIn = document.createElement('span')
    toasterContainer.appendChild(styleSlot)
    styleSlot.appendChild(renderIn)

    return {
        styleSlot,
        renderIn
    }
}

export function ToasterContainer({
    shadowRoot,
    testId = 'toasterContainer'
}: Readonly<{
    shadowRoot?: ShadowRoot | null
    testId?: string
}>) {
    let toasters: ReactNode[] = []

    const pushToasterEventListener = (event: Event) => {
        const toasterContainer = getToasterContainer(shadowRoot)
        if (toasterContainer) {
            const { styleSlot, renderIn } = getShadowDomContainer(toasterContainer)

            const newToaster = <Toaster {...(event as CustomEvent<ToasterProps>).detail} key={event.timeStamp} />
            toasters = [...toasters, newToaster]
            ReactDOM.createRoot(renderIn).render(<StyleSheetManager target={styleSlot}>{newToaster}</StyleSheetManager>)
        }
    }

    const clearToastsEventListener = () => {
        const toasterContainer = getToasterContainer(shadowRoot)
        if (toasterContainer) {
            const { styleSlot, renderIn } = getShadowDomContainer(toasterContainer)
            toasters.splice(0, toasters.length)
            ReactDOM.createRoot(renderIn).render(<StyleSheetManager target={styleSlot}>{toasters}</StyleSheetManager>)
        }
    }

    useEffect(() => {
        const toasterContainer = getToasterContainer(shadowRoot)
        if (toasterContainer) {
            toasterContainer.addEventListener(ToastEvent.PUSH, pushToasterEventListener)
            toasterContainer.addEventListener(ToastEvent.CLEAR_ALL, clearToastsEventListener)
            return () => {
                toasterContainer.removeEventListener(ToastEvent.PUSH, pushToasterEventListener)
                toasterContainer.removeEventListener(ToastEvent.CLEAR_ALL, clearToastsEventListener)
            }
        }
    })

    return (
        <ToasterSlot>
            <Wrapper data-testid={testId} id="toasterContainer" />
        </ToasterSlot>
    )
}

export const toast = (toasterProps: ToasterProps, shadowRoot?: ShadowRoot | null) => {
    const toasterContainer = getToasterContainer(shadowRoot)
    if (toasterContainer) {
        toasterContainer.dispatchEvent(new CustomEvent(ToastEvent.CLEAR_ALL))
        toasterContainer.dispatchEvent(new CustomEvent(ToastEvent.PUSH, { detail: toasterProps }))
    }
}
