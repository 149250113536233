import styled from 'styled-components'

import Card from '@nickel/core-ui/components/Card'
import Heading from '@nickel/core-ui/components/Heading'
import { References } from '@nickel/foundations/tokens/color'
import { Breakpoint, Spacing } from '@nickel/foundations/tokens/layout'
import { FontSize, FontWeight } from '@nickel/foundations/tokens/typography'

export const DisplayContent = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    @media (min-width: ${Breakpoint.MEDIUM}) {
        border-radius: ${Spacing.LARGE};
        box-shadow: 0 4px ${Spacing.LARGE} 0px rgba(25, 31, 41, 0.1);
        display: flex;
        flex-direction: column;
        flex: 0.33;
        gap: ${Spacing.XXLARGE};
        height: fit-content;
        padding: ${Spacing.LARGE} ${Spacing.LARGE} ${Spacing.LARGE} ${Spacing.LARGE};
    }
`

export const SubTitle = styled(Card.Header)`
    color: ${References.REF_SECONDARY_S200};
    font-weight: bold;
    padding: ${Spacing.XLARGE} ${Spacing.LARGE} ${Spacing.MEDIUM} 0;
    position: unset;

    @media (min-width: ${Breakpoint.MEDIUM}) {
        border-radius: ${Spacing.LARGE};
        padding: unset;
    }
`
export const MapItinerarySubTitle = styled(Card.Header)`
    display: none;

    @media (min-width: ${Breakpoint.MEDIUM}) {
        border-radius: ${Spacing.LARGE};
        color: ${References.REF_SECONDARY_S200};
        display: flex;
        font-size: ${FontSize.LARGE};
        font-weight: bold;
        padding: unset;
        position: unset;
    }
`

export const Title = styled(Heading.H1)`
    @media (min-width: ${Breakpoint.MEDIUM}) {
        font-weight: ${FontWeight.BOLDER};
        margin: ${Spacing.XXLARGE} 0;
    }
`

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${Spacing.LARGE};

    @media (min-width: ${Breakpoint.MEDIUM}) {
        flex-direction: row;
    }
`

export const BreadcrumbContainer = styled.div`
    padding: 0 ${Spacing.LARGE} ${Spacing.XLARGE} 0;

    @media (min-width: ${Breakpoint.MEDIUM}) {
        padding: ${Spacing.LARGE} ${Spacing.LARGE} 0 0;
    }
`

export const Wrapper = styled.div`
    padding: ${Spacing.LARGE};
`
