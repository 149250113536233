import styled from 'styled-components'

import { References } from '@nickel/foundations/tokens/color'
import { Spacing } from '@nickel/foundations/tokens/layout'

import { DisplayOnDrawer } from 'src/styles'

export const BannerHeight = '4rem'

export const DrawerHeader = styled.div<{ $isActive: boolean }>`
    align-items: center;
    display: ${({ $isActive }) => ($isActive ? 'flex' : 'none')};
    justify-content: space-between;
    margin-bottom: ${Spacing.XXLARGE};
    ${DisplayOnDrawer}
`

export const DrawerBackground = styled.div<{ $isActive: boolean }>`
    bottom: 0;
    display: none;
    height: 100dvh;
    left: 0;
    opacity: 0;
    position: fixed;
    width: 100vw;

    ${({ $isActive }) =>
        $isActive &&
        `
    animation: all 500ms ease-in;
    background-color: ${References.REF_OTHERS_LIGHTBOX};
    display: block;
    opacity: 1;
    inset: 0;
        overflow: hidden;
`}
`
export const DrawerAnimation = styled.div<{ $isActive: boolean }>`
    background-color: ${References.REF_NEUTRAL_N0};
    border-radius: 10px 10px 0 0;
    bottom: 0;
    height: 0;
    left: 0;
    position: fixed;
    width: 100%;

    ${({ $isActive }) =>
        $isActive &&
        `
            animation: height 500ms ease-in;
            height: calc(100dvh - ${BannerHeight});
        `}
`
