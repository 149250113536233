import React from 'react'

import { useTranslation } from 'react-i18next'

import { XCircle } from '@nickel/core-ui/components/Icons'
import { IconSize } from '@nickel/core-ui/components/Icons/types'
import { References } from '@nickel/foundations/tokens/color'

import { Wrapper } from './styles'

export function EmptySearchResult() {
    const { t } = useTranslation('home')

    return (
        <Wrapper data-testid="emptySearchResult">
            <XCircle color={References.REF_NEUTRAL_N300} size={IconSize.LARGE} />
            {t('emptyDesktopResult')}
        </Wrapper>
    )
}
