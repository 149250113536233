import React from 'react'

import { useTranslation } from 'react-i18next'

import SearchInput from '@nickel/core-ui/components/SearchInput'
import { noop } from '@nickel/core-ui/utils/utils'

import { SearchInputWrapper } from '../SearchBarInput/styles'

export function HeaderMobileSearchBarInput({
    onClick,
    searchQuery,
    setSearchQuery
}: Readonly<{
    onClick: () => void
    searchQuery: string
    setSearchQuery: React.Dispatch<React.SetStateAction<string>>
}>) {
    const { t } = useTranslation('home')

    return (
        <SearchInputWrapper $isActive={false} data-testid="headerMobileSearchBarInput#button" onClick={onClick}>
            <SearchInput
                className="customSearchInput"
                displaySuggestion={false}
                label=""
                name="searchButton"
                onChange={setSearchQuery}
                onSelect={noop}
                options={[]}
                placeholder={t('searchPlaceholder')}
                testId="headerMobileSearchBarInput"
                value={searchQuery}
            />
        </SearchInputWrapper>
    )
}
