import React from 'react'

import { useTranslation } from 'react-i18next'

import Spinner from '@nickel/core-ui/components/Spinner'

import { useLangCountry } from '../../hooks/useLangCountry'

import { useGetStoresCount } from './hook/useGetStoresCount'
import { Wrapper, Title } from './styles'

export function StoreList() {
    const { country } = useLangCountry()

    const { storeCount, isFetching } = useGetStoresCount(country)

    const { t } = useTranslation('home')
    const countryTranslated = t(`country.${country}`)

    return (
        <Wrapper data-testid="storeList">
            {!isFetching && (
                <Title data-testid="storeList#title">
                    {t('storeList.title', {
                        count: storeCount || 0,
                        country: countryTranslated
                    })}
                </Title>
            )}
            {isFetching && <Spinner size="small" />}
        </Wrapper>
    )
}
