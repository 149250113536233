import React, { useContext, useEffect, useState } from 'react'

import { Status, Wrapper } from '@googlemaps/react-wrapper'
import { ErrorBoundary } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'

import { ChevronLeft, Danger, Search } from '@nickel/core-ui/components/Icons'
import { IconSize } from '@nickel/core-ui/components/Icons/types'
import Spinner from '@nickel/core-ui/components/Spinner'

import { FilterChip } from './components/Filters/FilterChip'
import { Map } from './components/Map/Map'
import { getDefaultZoom, reloadButtonVisible } from './domain/domain'
import { Chip, Chips, ErrorLoader, GoogleMap, MapContainer, MapHoverActions } from './styles'

import { PosCard } from 'src/components/PosCard/PosCard'
import { AppContext } from 'src/context'
import { AppActionsType } from 'src/context/types'
import { useLangCountry } from 'src/hooks/useLangCountry'
import { useMediaQuery } from 'src/hooks/useMediaQuery'
import { LightStore } from 'src/services/annuaire-buraliste-api-v3'

export function MapWrapper({
    hasResults,
    saveSearchAndNavigate,
    searchResultOpen,
    setSearchResultOpen,
    storePinned,
    storeLoading
}: Readonly<{
    saveSearchAndNavigate: (storeId: string) => void
    hasResults: boolean
    searchResultOpen: boolean
    setSearchResultOpen: React.Dispatch<boolean>
    storeLoading: boolean
    storePinned: LightStore | undefined
}>) {
    const { t } = useTranslation('home')
    const isDesktop = useMediaQuery()
    const [searchZoneVisible, setSearchZoneVisible] = useState<boolean>(false)
    const [boundingBox, setBoundingBox] = useState<google.maps.LatLngBounds | undefined>(undefined)
    const { state, dispatch } = useContext(AppContext)

    const { country, lang } = useLangCountry()

    const [zoom, setZoom] = useState<number>(getDefaultZoom(country))

    const _searchInZone = () => {
        dispatch({
            type: AppActionsType.SET_MAP_POSITION,
            payload: {
                shouldSearch: true,
                viewport: boundingBox
            }
        })
    }

    useEffect(() => {
        setSearchZoneVisible(
            reloadButtonVisible({ boundingBox, isDesktop, searchPosition: state.searchPosition, zoom })
        )
    }, [boundingBox, isDesktop, state.searchPosition, zoom])

    const fallbackRender = () => {
        return (
            <ErrorLoader role="alert">
                <Danger size={IconSize.LARGE} />
                <em>{t('errors:default')}</em>
            </ErrorLoader>
        )
    }

    const loadingRender = (status: Status) => {
        switch (status) {
            case Status.FAILURE:
                return fallbackRender()
            default:
                return (
                    <GoogleMap data-testid="map#skeleton">
                        <Skeleton height="100%" width="100%" />
                    </GoogleMap>
                )
        }
    }

    return (
        <MapContainer data-testid="mapContainer">
            {isDesktop && (
                <MapHoverActions>
                    <Chips data-testid="maps#chips">
                        {!searchResultOpen && hasResults && (
                            <Chip data-testid="displayResultButton" onClick={() => setSearchResultOpen(true)}>
                                <ChevronLeft size={IconSize.SMALL} />
                                <u>{t('displayResults')}</u>
                            </Chip>
                        )}
                        <FilterChip />
                        {searchZoneVisible && (
                            <Chip data-testid="searchZone" onClick={() => _searchInZone()}>
                                {!storeLoading ? <Search size={IconSize.SMALL} /> : <Spinner />}
                                <u>{t('searchZone')}</u>
                            </Chip>
                        )}
                    </Chips>
                    {storePinned && (
                        <PosCard saveSearchAndNavigate={saveSearchAndNavigate} selected store={storePinned} />
                    )}
                </MapHoverActions>
            )}
            <ErrorBoundary fallbackRender={fallbackRender}>
                <Wrapper
                    apiKey={window.REACT_APP_GOOGLE_MAPS_KEY}
                    language={lang}
                    libraries={['core', 'maps', 'marker']}
                    render={loadingRender}
                >
                    <Map setBoundingBox={setBoundingBox} setZoom={setZoom} zoom={zoom} />
                </Wrapper>
            </ErrorBoundary>
        </MapContainer>
    )
}
