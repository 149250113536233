import React from 'react'

import { useTranslation } from 'react-i18next'

import Text from '@nickel/core-ui/components/Text'

import { SubTitle } from '../styles'

import { CardContent, ContentInfos, ContentText } from './styles'

import { OpeningTag } from 'src/components/OpeningTag/OpeningTag'
import { Store } from 'src/services/annuaire-buraliste-api-v3'

function Infos({ store }: Readonly<{ store: Store }>) {
    const { t } = useTranslation('infos')
    const { address, openingDays } = store
    return (
        <CardContent>
            <SubTitle>
                <h1 className="heading-s">{t('storeInfos')}</h1>
            </SubTitle>
            {openingDays && <OpeningTag openingDays={openingDays} timezone={store.address?.timezone} />}
            <ContentInfos>
                <ContentText>
                    <Text>{address?.address}</Text>
                    <Text>{`${address?.zipCode}  ${address?.city}`}</Text>
                </ContentText>
            </ContentInfos>
        </CardContent>
    )
}

export default Infos
