import React, { useContext, useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import InfiniteScrollPagination from '@nickel/core-ui/components/Pagination/Variant/ZeroBasedPageIndexInfiniteScroll/InfiniteScrollPagination'

import { EmptySearchResult } from './components/EmptySearchResult'
import { LoadingSearchResult } from './components/LoadingSearchResult'
import { PosList, ResultDetailMessage, Title, Wrapper } from './styles'

import { PosCard } from 'src/components/PosCard/PosCard'
import { AppContext } from 'src/context'
import { AppActionsType } from 'src/context/types'
import { STORES_PAGE_SIZE } from 'src/screens/Home/domain/domain'
import { PageResponseStores } from 'src/services/annuaire-buraliste-api-v3'
import { CrossButton } from 'src/styles'
import { SnowplowAction, trackClickEvent } from 'src/utils/Tracking/utils'

export function SearchResult({
    saveSearchAndNavigate,
    searchStores,
    setSearchResultOpen,
    stores
}: Readonly<{
    saveSearchAndNavigate: (storeId: string) => void
    searchStores: boolean
    setSearchResultOpen: React.Dispatch<boolean>
    stores: PageResponseStores | undefined
}>) {
    const { t } = useTranslation('home')
    const { state, dispatch } = useContext(AppContext)

    const _onPageChange = (targetPage: number) => {
        dispatch({
            type: AppActionsType.SET_PAGE_NUMBER,
            payload: targetPage
        })
    }

    useEffect(() => {
        const posList = document.querySelector('#widget-store-locator')?.shadowRoot?.getElementById('posList')
        if (state.storePinnedId !== undefined && state.searchResult.pageSize !== undefined) {
            const shadowStore = document
                .querySelector('#widget-store-locator')
                ?.shadowRoot?.getElementById(`pos-${state.storePinnedId}`)
            if (shadowStore && posList) {
                posList.scrollTop = shadowStore.offsetTop - posList.offsetTop
                dispatch({
                    type: AppActionsType.SET_SEARCH_RESULT
                })
            }
        } else if (state.pageNumber === 0) {
            if (posList) posList.scrollTo({ top: 0 })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stores])

    const _onClose = () => {
        trackClickEvent(SnowplowAction.CLOSE_SEARCH_PANEL)
        setSearchResultOpen(false)
    }

    return (
        <Wrapper data-testid="searchResult">
            <Title className="heading-s">
                {t('searchResult')}
                <CrossButton data-testid="closePosCard" onClick={_onClose} />
            </Title>
            {stores && stores.content.length > 0 && (
                <>
                    <ResultDetailMessage data-testid="resultDetailMessage">
                        {t('resultDetailMessage', { count: stores.paging.totalElements })}
                    </ResultDetailMessage>
                    <PosList id="posList">
                        {stores &&
                            stores.content.map((lightStore) => {
                                return (
                                    <div key={`pos-${lightStore.id}`} id={`pos-${lightStore.id}`}>
                                        <PosCard saveSearchAndNavigate={saveSearchAndNavigate} store={lightStore} />
                                    </div>
                                )
                            })}
                        <InfiniteScrollPagination
                            key={state.pageNumber}
                            ariaControlsId=""
                            loading={searchStores}
                            onPageChange={_onPageChange}
                            pageIndex={state.pageNumber}
                            pageSize={STORES_PAGE_SIZE}
                            testId="searchResult#list"
                            totalElements={stores?.paging?.totalElements ?? 1}
                        />
                    </PosList>
                </>
            )}
            {(stores === undefined || stores.content.length === 0) && !searchStores && <EmptySearchResult />}
            {searchStores && !stores && <LoadingSearchResult />}
        </Wrapper>
    )
}
