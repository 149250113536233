import React, { useEffect } from 'react'

import { Navigate } from 'react-router-dom'

import { Home } from '../screens/Home/Home'

import { AUTHORIZE_LANG_COUNTRY, DRUPAL_HOST, HOME_ROUTES } from './constants'

import { useLangCountry } from 'src/hooks/useLangCountry'
import { changeLanguageWithCulture } from 'src/i18n'

export function RedirectToHomePage() {
    const { key } = useLangCountry()

    const getHomeLink = Object.values(AUTHORIZE_LANG_COUNTRY).includes(key)
        ? AUTHORIZE_LANG_COUNTRY[key]
        : AUTHORIZE_LANG_COUNTRY.FR_FR

    useEffect(() => {
        changeLanguageWithCulture()
    }, [])

    if (window.location.hostname.includes(DRUPAL_HOST)) return <Home />

    return <Navigate to={HOME_ROUTES[getHomeLink]} />
}
