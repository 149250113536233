import React, { useEffect } from 'react'

import { DrawerAnimation, DrawerBackground, DrawerHeader } from './style'

import { CrossButton } from 'src/styles'
import { disableScroll } from 'src/utils/layout/layout'

export function Drawer({
    isActive,
    onClose,
    title
}: Readonly<{
    isActive: boolean
    onClose: (e: React.MouseEvent) => void
    title: string
}>) {
    useEffect(() => {
        if (isActive) {
            disableScroll()
        }
    }, [isActive])

    return (
        <div data-testid="drawer">
            <DrawerBackground $isActive={isActive} />
            <DrawerHeader $isActive={isActive}>
                <h1 className="heading-s">{title}</h1>
                <CrossButton data-testid="closeDrawer" onClick={onClose} />
            </DrawerHeader>
            <DrawerAnimation $isActive={isActive} />
        </div>
    )
}
